import { createApp, defineComponent, ref } from 'vue/dist/vue.esm-bundler';
import Toast from '@components/Core/Toast.vue';
const FlashMessageContainer = defineComponent({
    name: 'FlashMessageContainer',
    components: {
        Toast,
    },
    setup() {
        const messages = ref([]);
        const customStyles = (i) => ({
            'toast-container': {
                'top': !i ? '1rem' : `${(4.5 * i) + 1}rem`,
            }
        });
        document.addEventListener('FlashMessage.addMessage', (e) => messages.value.push(e.detail));
        document.addEventListener('FlashMessage.clearAll', () => messages.value = []);
        return { messages, customStyles };
    }
});
if (document.getElementById('flash-message-container')) {
    createApp(FlashMessageContainer).mount('#flash-message-container');
}
